import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        name: 'home',
        redirect: 'dashboard',
        component: () => import('@/views/layout.vue'),
        meta: {
            isSideBarMenu: true
        },
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard.vue'),
                meta: {
                    title: '概览',
                    icon: 'dashboard'
                }
            }
        ]
    },
    {
        path: '/vote',
        name: 'vote',
        component: () => import('@/views/layout.vue'),
        meta: {
            title: '投票',
            icon: 'vote',
            isSideBarMenu: true
        },
        children: [
            {
                path: '/vote/result',
                name: 'voteResult',
                component: () => import('@/views/vote/result.vue'),
                meta: {
                    title: '投票结果'
                }
            },
            {
                path: '/vote/comment',
                name: 'voteComment',
                component: () => import('@/views/vote/comment.vue'),
                meta: {
                    title: '投票评论'
                }
            },
            {
                path: '/vote/manager',
                name: 'voteManager',
                component: () => import('@/views/vote/manager.vue'),
                meta: {
                    title: '投票管理'
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
