import Cookies from 'js-cookie'

const get = (key: string): string => {
    const cookie = Cookies.get(key)
    return cookie ? cookie : ''
}

const set = (key: string, value: string, seconds = 7200, path = '/'): void => {
    const endTime = Date.now() + seconds * 1000
    const expires = new Date(endTime)
    Cookies.set(key, value, {expires, path})
}

const del = (key: string): void => {
    Cookies.remove(key)
}

export default {
    get,
    set,
    del
}
