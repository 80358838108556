import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import SvgIconPlugin from '@/plugins/SvgIconPlugin'
import '@/permission'

import 'element-plus/dist/index.css'

createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus, {locale})
    .use(SvgIconPlugin, {imports: []})
    .mount('#app')
