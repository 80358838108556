import routers from '@/router'
import NProgress from 'nprogress'
import cookie from '@/utils/cookie'

import 'nprogress/nprogress.css'

NProgress.configure({showSpinner: false})

const whiteUrlList = ['/login', '/no-permission']
routers.beforeEach(async (to, from, next) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.document.title = to.meta.title
    NProgress.start()
    if (whiteUrlList.indexOf(to.path) > -1) {
        await next()
    } else {
        const token = cookie.get('token')
        if (token) {
            await next()
            // const hasPermission = await permission(to.path)
            // if (hasPermission) {
            //     await next()
            // } else {
            //     const content = `无权限访问：${window.document.title}`
            //     await next({name: 'NoPermission', query: {content}, replace: true})
            // }
        } else {
            const path = encodeURIComponent(to.fullPath)
            await next({name: 'login', query: {path}, replace: true})
        }
    }
    NProgress.done()
})

// routers.afterEach((to, from) => {
//     console.log(from, to)
// })
