import SvgIcon from '@/components/SvgIcon.vue'

const componentPlugin: any = {
    install: function(vue: any, options: any) {
        if (
            options &&
            options.imports &&
            Array.isArray(options.imports) &&
            options.imports.length > 0
        ) {
            // 按需引入图标
            const { imports } = options;
            imports.forEach((name: any) => {
                require(`@/assets/svgs/${name}.svg`);
            });
        } else {
            // 全量引入图标
            const ctx = require.context('@/assets/svgs', false, /.svg$/);
            ctx.keys().forEach(path => {
                const temp = path.match(/.([A-Za-z0-9-_]+).svg$/);
                if (!temp) return;
                const name = temp[1];
                require(`@/assets/svgs/${name}.svg`);
            });
        }
        vue.component(SvgIcon.name || 'svg-icon', SvgIcon);
    }
}

export default componentPlugin
