<template>
  <router-view/>
</template>

<style lang="less">
html {
  font-size: 62.5%; // 10px
}

body {
  padding: 0;
  margin: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  font-size: 1.4rem;
}

.container-box {
  background: white;
  min-height: calc(100vh - 0.9rem);
  border-radius: 0.5rem;
}

.search-bar {
  background-color: white;
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-bottom: 0.1rem solid #efefef;
  border-radius: 0.5rem 0.5rem 0 0;

  .el-form-item {
    margin-right: 1.5rem !important;
  }

  .el-form-item:last-child {
    margin-right: 0 !important;
  }
}

.el-drawer__header {
  padding: 1.5rem 2rem !important;
  margin-bottom: 0 !important;
  border-bottom: 0.1rem solid #dcdfe6;

  .el-drawer__title {
    font-size: 1.6rem !important;
  }
}

.el-drawer__body {
  padding: 2rem !important;
}
</style>
